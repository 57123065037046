.upload {
  width: 100%;
  height: 100%;
  border: 2px dashed #0059de;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.7rem;
  flex-direction: column;
  background-color: #f0f5fe;
  color: black;
  cursor: pointer;
  font-size: 2.2rem;
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%230059DEFF' stroke-width='4' stroke-dasharray='6%2c 28' stroke-dashoffset='1' stroke-linecap='square'/%3e%3c/svg%3e"); */
  border-radius: 5px;   
}
.upload span{
  font-size: 0.9rem;
  color: #f70b0b;
  font-weight: 600;
}
.upload:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
