.upload {
  width: 100%;
  height: 100%;
  border: 2px dashed #0059de;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.7rem;
  flex-direction: column;
  background-color: #f0f5fe;
  color: black;
  cursor: pointer;
  font-size: 2.2rem;
  border-radius: 5px;
}
.upload:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
